<template>
  <div>
    <div v-if="pageLoad">
      <PageLoader></PageLoader>
    </div>
    <div v-else class="container request-holder">
      <div v-if="cardrequests.length > 0">
        <h6>Card requests</h6>
        <div
          class="requests"
          v-for="(item, index) in cardrequests"
          :key="index"
        >
          <div>
            <p>{{ item.user.firstname }} {{ item.user.lastname }}</p>
            <p v-if="item.cardtype">{{ item.cardtype.cardname }} Card</p>
            <p>{{ item.updated_at | moment2 }}</p>
          </div>
          <div>
            <button class="btn" @click="openLinkCardModal(item.user, item)">
              Link card
            </button>
          </div>
        </div>
      </div>
      <div v-else class="no-request">
        <p>No card request</p>
      </div>
    </div>
    <new-user-modal></new-user-modal>
  </div>
</template>

<script>
import { Service } from "../../store/service";
import moment from "moment";
import NewUserModal from "./NewUserModal.vue";
import PageLoader from "../PageLoader.vue";
import $ from "jquery";
const Api = new Service();

export default {
  name: "CardRequestUsers",
  components: {
    NewUserModal,
    PageLoader
  },
  // props: ["userData"],
  data() {
    return {
      loader: false,
      pageLoad: false,
      cardrequests: []
    };
  },
  computed: {
    welcome() {
      return `${this.userData}`;
    }
  },
  //   created() {
  //     this.$root.$refs.createpinmodal = this;
  //     this.$root.$refs.changePinModal = this;
  //     // this.$root.$refs.X = this;
  //   },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    // this.getDetails();
    if (this.user.overdraft_user_type === "agent") {
      this.fetchCustomerCardRequests();
    }
    if (this.cardrequests.length) {
      this.$router
        .push({
          path: `/dashboard/ovadraft`
        })
        .catch(() => {});
    }
  },
  methods: {
    // openCardUpgradeModal(cardDetail) {
    //   this.$root.$refs.cardmodal.opencardupgrade(cardDetail);
    // },
    triggerChangePin() {
      $("#changepin")
        .addClass("fade")
        .modal("show");
    },
    openLinkCardModal(item, cardDetails) {
      // $("#linkcard")
      //   .addClass("fade")
      //   .modal("show");
      this.$root.$refs.linkcardmodal.openLinkCardModal2(item, cardDetails);
    },
    async fetchCustomerCardRequests() {
      this.pageLoad = true;
      try {
        await Api.getRequest("agentfetchuseroverdraftcardrequest")
          .then(res => {
            this.pageLoad = false;
            // console.log("users", res);
            if (res.data.success) {
              this.cardrequests = res.data.data;
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
            return this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("closed");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    moment2: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$sheltaBlack: #262626;
$offBlack: #262626;
$limeGreen: #3ec250;
$sheltaGrey: #555c74;

.request-holder {
  background: $secondary;
  height: calc(100vh - 3.46rem);
  overflow: auto;
  padding-top: 30px;
  h6 {
    color: $sheltaGrey;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 20px;
    text-align: left;
  }
}
.requests {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 10px;
  p {
    color: $sheltaGrey;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
    text-align: left;
  }
  button {
    background: #235637;
    color: $secondary;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    border-radius: 20px;
    font-weight: 500;
    box-shadow: none;
    outline: 0;
  }
}
.no-request {
  border: 1px dashed #e2e8f0;
  border-radius: 4px;
  width: 100%;
  height: 80px;
  margin-bottom: 30px;
  p {
    color: $sheltaGrey;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
    text-align: center;
    margin: 2% auto;
  }
}
</style>
